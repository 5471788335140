import React from 'react'
import { Icon } from '@iconify/react';
import winnerLogo from '../../../../assets/lottery-winner-logo.svg'
import winnerTrophy from '../../../../assets/winner_trophy.svg'
import { formatToIndianNumber } from '../../../../helpers/numberConversion';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Loader from '../../../../helpers/Loader';
import avatar from '../../../../assets/adminAvatar.svg'

export default function Winner() {
  const { id } = useParams();

  const { getAccessToken } = useAuth();

  const fetchContestWinner = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.getContestWinner}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Contest Winner");
      }
      const result = await response.json();
      console.log("Contest Winner", result?.response?.winner);
      return result?.response?.winner;
    } catch (error) {
      throw new Error("Failed to Fetch Contest Winner");
    }
  };
  const { data: contestWinner, isLoading, isError, error } = useQuery(
    ['contestWinner', id],
    fetchContestWinner
  );

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Contest Winner Data...!
        </h1>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='flex justify-center items-center mt-24'>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className='flex flex-col xs:flex-row xs:justify-between xs:items-center gap-2'>
        <h1 className="text-lg sm:text-xl font-medium">
          Winner
        </h1>
        <div className='flex items-center gap-2 sm:gap-4'>
          <button
            className="flex items-center gap-2 text-sm sm:text-base font-medium text-white bg-primary 
            py-[6px] sm:py-2 px-4 rounded-md"
          >
            <Icon icon="ic:round-notifications-active" className='text-lg sm:text-xl' />
            Send Notifications
          </button>
          <button
            className="flex items-center gap-2 text-sm sm:text-base font-medium text-white bg-primary 
            py-[6px] sm:py-2 px-4 rounded-md"
          >
            <Icon icon="tabler:download" className='text-lg sm:text-xl' />
            Export
          </button>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-2 max-lg:mt-4'>
        <div className='sm:p-2 bg-[#E0E0E0] w-auto rounded-full'>
          <img src={winnerLogo} alt="" />
        </div>
        <h1 className='text-xl sm:text-2xl font-semibold'>Winner</h1>
        <p className='text-[#858585] max-sm:text-sm'>
          Here is the winner of this Contest.
        </p>
        <div className='flex gap-14 mt-4 justify-center flex-wrap mb-8'>
          <div className='text-white sm:min-w-[410px] sm:min-h-[240px] max-sm:max-w-full rounded-[14px] px-2 sm:px-6 py-3 flex flex-col gap-4'
            style={{ background: "linear-gradient(180deg, #A967FF 0%, #5500C3 100%)" }}
          >
            <div className='flex items-center justify-between border-b border-[#F0F4F9]'>
              <div className='flex gap-6 items-center'>
                <label htmlFor="winner"
                  className='h-10 w-10 rounded-full font-medium flex items-center justify-center'
                  style={{ background: "linear-gradient(180deg, #A967FF 0%, #5500C3 100%)" }}
                >
                  #1
                </label>
                <h2 className='text-xl font-medium'>
                  Winner
                </h2>
              </div>
              <img src={winnerTrophy} alt="trophy" />
            </div>
            <div className='flex items-start justify-between flex-wrap gap-1'>
              <div className='flex items-center gap-4'>
                <img src={avatar} alt="pic" className='h-[56px] w-[56px] rounded-full' />
                <div className='flex flex-col'>
                  <h2 className='font-medium'>
                    {(contestWinner?.userId?.FirstName || contestWinner?.userId?.LastName) ? contestWinner?.userId?.FirstName + " " + contestWinner?.userId?.LastName : "NA"}
                  </h2>
                  <h5 className='text-[13px] font-light sm:font-normal'>
                    {contestWinner?.userId?.email}
                  </h5>
                </div>
              </div>
              <Link to={`/admin/users/view/${contestWinner?.userId?.customerId}`}
                className='text-[11px] font-medium px-2 py-[2px] rounded-md mt-2 text-center'
                style={{ background: "rgba(255, 255, 255, 0.10)" }}
              >
                View Profile
              </Link>
            </div>
            {
              contestWinner?.contestId?.prizeType === "money" ? (
                <h1 className='text-[22px] xs:text-[28px] font-semibold text-center'>
                <i>INR {formatToIndianNumber(contestWinner?.contestId?.prizeInfo?.amount)}</i>
              </h1>
              ) : contestWinner?.contestId?.prizeType === "mobile" ? (
                <h1 className='text-[28px] font-semibold text-center'>
                <i>{contestWinner?.contestId?.prizeInfo?.mobileName}</i>
              </h1>
              ) : (
                <h1 className='text-[28px] font-semibold text-center'>
                  <i>{contestWinner?.contestId?.prizeInfo?.carName}</i>
                </h1>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

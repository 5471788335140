import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { toast } from 'react-toastify';


export default function DeleteModal({ close, refetch, mongoId }) {
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.deleteContest}/${mongoId}`, {
        method: 'DELETE',
        body: JSON.stringify({
          "remark": data?.remark,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error("Failed to Archive Contest");
      }
      console.log(result);
      toast.success("Contest Archived Successfully");
      refetch();
      close();
      reset();
    } catch (error) {
      console.error("Delete contest error: ", error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='w-screen px-2 flex justify-center items-center'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='bg-white w-full max-w-[600px] rounded-lg flex flex-col gap-4 p-4 xs:p-6 overflow-y-auto'>
        <div className='flex justify-between items-center'>
          <h2 className='text-xl sm:text-2xl font-semibold'>
            Delete Contest
          </h2>
          <button
            className='border border-[#FF0000] bg-red-100 rounded-[4px]'
            type='button'
            onClick={() => close()}>
            <Icon
              icon='material-symbols:close'
              className='text-[#FF0000] text-2xl p-[2px]'
            />
          </button>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="remark" className="text-sm sm:text-base text-[#858585]">
            Remark<span className="text-red-500">*</span>
          </label>
          <textarea rows={5}
            className="resize-none text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
            type="text"
            placeholder="Enter Remark"
            {...register("remark", {
              required: "*Enter Remark to Delete",
            })}
          />
          {errors.remark && (
            <div className="text-sm text-red-500">
              {errors.remark.message}
            </div>
          )}
        </div>
        <div className="flex gap-6 items-center justify-end text-base sm:text-lg mt-4 font-medium">
          <button onClick={() => close()}
            className="text-primary bg-white border border-primary py-[0.3rem] w-40 rounded-md"
            type="button"
          >
            Cancel
          </button>

          <button
            className="text-white bg-primary py-[0.4rem] w-40 rounded-md text-center"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div className='flex items-center justify-center'>
                <ThreeDots
                  height="30"
                  width="45"
                  radius="8"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : "Delete"
            }
          </button>
        </div>
      </form>
    </div>
  )
}

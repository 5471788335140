import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Icon } from "@iconify/react";
import OffersList from "./OffersList";


export default function OffersContainer() {
  const [isEditForm, setIsEditForm] = useState(false);
  const [updateOffersList, setUpdateOffersList] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    const newPath = pathname.split("/");
    if (_.includes(newPath, "edit")) {
      setIsEditForm(true);
    } else {
      setIsEditForm(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div className="flex flex-col gap-4 px-0 sm:px-2 py-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[1rem] text-primary">
          <Icon icon="bxs:offer" className="text-2xl sm:text-3xl" />
          <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
            {isEditForm ? "Edit Offer" : "Add New Offer"}
          </h1>
        </div>
        {isEditForm && (
          <Link to={"/admin/offers-banners"}>
            <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <Icon
                icon="material-symbols:add"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
              <span className="whitespace-nowrap">Create Offer</span>
            </button>
          </Link>
        )}
      </div>
      <div>
        <Outlet context={[setUpdateOffersList]} />
      </div>
      <div className="my-4">
        <OffersList updateOffersList={updateOffersList} setUpdateOffersList={setUpdateOffersList} />
      </div>
    </div>
  );
}

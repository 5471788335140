import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import winnerLogo from '../../../../assets/lottery-winner-logo.svg'
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

export default function AnnounceWinner({ refetch }) {
  const { id } = useParams();
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { getAccessToken } = useAuth();

  const announceWinnerHanlder = async () => {
    const token = await getAccessToken();
    setLoaderBtn(true);
    try {
      const response = await fetch(`${APIurls.announceContestWinner}/${id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        const errorResult = await response.json();
        toast.error(errorResult.message || "Failed to announce winner");
        throw new Error(errorResult.message);
      }
      const result = await response.json();
      console.log("Announce Winner result", result);
      toast.success("Winner Announced Successfully!");
      refetch()
    } catch (error) {
      console.error(error.message);
    }
    setLoaderBtn(false);
  }

  return (
    <div>
      <div className='flex flex-col xs:flex-row xs:justify-between xs:items-center gap-2'>
        <h1 className="text-lg sm:text-xl font-medium">
          Winner
        </h1>
        <div className='flex items-center gap-2 sm:gap-4'>
          <button
            className="flex items-center gap-2 text-sm sm:text-base font-medium text-white bg-primary 
            py-[6px] sm:py-2 px-4 rounded-md"
          >
            <Icon icon="ic:round-notifications-active" className='text-lg sm:text-xl' />
            Send Notifications
          </button>
          <button
            className="flex items-center gap-2 text-sm sm:text-base font-medium text-white bg-primary 
            py-[6px] sm:py-2 px-4 rounded-md"
          >
            <Icon icon="tabler:download" className='text-lg sm:text-xl' />
            Export
          </button>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-2 max-lg:mt-4'>
        <div className='sm:p-2 bg-[#E0E0E0] w-auto rounded-full'>
          <img src={winnerLogo} alt="" />
        </div>
        <h1 className='text-xl sm:text-2xl font-semibold'>Winner</h1>
        <p className='text-[#858585] max-sm:text-sm'>
          Yet to be Announced.
        </p>
        <button onClick={announceWinnerHanlder}
          disabled={loaderBtn}
          className="mt-2 mb-8 text-sm sm:text-base font-medium text-white bg-primary py-2 px-4 rounded-md whitespace-nowrap w-48 sm:w-52"
        >
          {loaderBtn ? (
            <div className='flex items-center justify-center w-40'>
              <ThreeDots
                height="20"
                width="40"
                radius="8"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : "Announce Winner"
          }
        </button>
      </div>
    </div>
  )
}

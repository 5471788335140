import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useForm } from 'react-hook-form';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Option, Select } from '@material-tailwind/react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Loader from '../../../helpers/Loader';
// import 'swiper/css/scrollbar';

export default function EditContest() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [selectDate, setSelectDate] = useState();
  const [showError, setShowError] = useState(false);
  const [prizeType, setPrizeType] = useState("");
  const [thumbnailImg, setThumbnailImg] = useState();
  const [sliderImgs, setSliderImgs] = useState([]);
  const [verticalImg, setVerticalImg] = useState();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [contestId, setContestId] = useState("");
  const [tag, setTag] = useState("");
  const [carPrize, setCarPrize] = useState({
    carName: "",
    carModel: "",
    exShowroomPrice: "",
    RTO: "",
    insurance: "",
    onRoadPrice: "",
    cityName: "",
    others: ""
  });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();

  const addSliderImages = async (e) => {
    const newFiles = Array.from(e.target.files);
    setSliderImgs((prevState) => [...prevState, ...newFiles]);
  }
  const removeSliderImages = (index) => {
    setSliderImgs((prevState) => prevState.filter((_, i) => i !== index));
  }

  const fetchContestById = async () => {
    const token = await getAccessToken()
    setFetchLoader(true);
    try {
      const response = await fetch(`${APIurls.getContestById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        const errorResult = await response.json();
        toast.error(errorResult.message || "Failed to Fetch Contest");
        throw new Error("Failed to Fetch Contest");
      }
      const result = await response.json();
      const { contestId, name, description, numberOfParticipant, contestPrice, probablyAnnounceDate, prizeType, horizontalThumbnail, verticalThumbnail, sliderImg, prizeInfo, tag } = result?.response?.contest;
      setContestId(contestId);
      setValue("name", name);
      setValue("description", description);
      setValue("numberOfParticipant", numberOfParticipant);
      setValue("contestPrice", contestPrice);
      setSelectDate(probablyAnnounceDate);
      setPrizeType(prizeType);
      setThumbnailImg(horizontalThumbnail);
      setVerticalImg(verticalThumbnail);
      setSliderImgs(sliderImg);
      setTag(tag);
      // setValue("carPrize", prizeInfo?.amount || "");
      // setValue("phonePrize", prizeInfo?.mobileName || "");
      // setCarPrize(prizeInfo);
      if (prizeType === "money") {
        setValue("cashPrize", prizeInfo.amount);
      } else if (prizeType === "mobile") {
        setValue("phonePrize", prizeInfo.mobileName);
      } else if (prizeType === "car") {
        setCarPrize(prizeInfo);
      }
      console.log("Single Contest", result?.response?.contest)
      return result?.response?.contest;
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchContestById();
  }, [])

  const onSubmit = async (data) => {
    console.log(data);
    const isCarFieldsValid = Object.values(carPrize).every((field) => field !== "");
    if (!(selectDate && prizeType && (prizeType !== "car" || isCarFieldsValid) && thumbnailImg && verticalImg && sliderImgs.length > 0)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    console.log(data);
    const { name, description, phonePrize, cashPrize, contestPrice, numberOfParticipant } = data;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("numberOfParticipant", numberOfParticipant);
    formData.append("contestPrice", contestPrice);
    formData.append("announceDate", selectDate);
    formData.append("prizeType", prizeType);
    formData.append("horizontalThumbnail", thumbnailImg);
    formData.append("verticalThumbnail", verticalImg);
    if (tag) {
      formData.append("tag", tag);
    }
    sliderImgs?.forEach((file) => {
      formData.append("sliderImg", file);
    })
    if (prizeType === "money") {
      formData.append("prizeInfo", JSON.stringify({
        amount: cashPrize,
      }));
    } else if (prizeType === "mobile") {
      formData.append("prizeInfo", JSON.stringify({
        mobileName: phonePrize,
      }));
    } else if (prizeType === "car") {
      formData.append("prizeInfo", JSON.stringify(carPrize));
    }
    const token = await getAccessToken();
    const requestOptions = {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    setLoader(true);
    try {
      const response = await fetch(`${APIurls.updateContest}/${contestId}`, requestOptions);
      if (!response.ok) {
        const errorResult = await response.json();
        toast.error(errorResult.message || "Failed to update contest");
        throw new Error(errorResult.message || "Failed to update contest");
      }
      const result = await response.json();
      toast.success("Contest updated successfully");
      console.log("Contest updated successfully", result);
      navigate("/admin/contests");
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoader(false);
    }
  }

  if (fetchLoader) {
    return (
      <div className='flex justify-center items-center mt-52'>
        <Loader />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6 font-normal sm:px-4 py-2 mb-8">
      <div className="text-primary flex items-center gap-4">
        <Icon icon="game-icons:ticket" className="text-3xl" />
        <h1 className="text-lg sm:text-3xl font-semibold">
          Update Contest
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='sm:px-4 xl:px-12'>
        <div className="flex flex-col md:flex-row gap-3 md:gap-12 lg:gap-16 xl:gap-40">
          <div className='flex flex-col gap-3 sm:gap-4 flex-1'>
            <div className="flex flex-col gap-1">
              <label htmlFor="contestName" className="text-sm sm:text-base text-[#858585]">
                Name of Contest<span className="text-red-500">*</span>
              </label>
              <input
                className="text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                type="text"
                placeholder="Enter Name"
                {...register("name", {
                  required: "*This field is required",
                })}
              />
              {errors.name && (
                <div className="text-sm text-red-500">
                  {errors.name.message}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="description" className="text-sm sm:text-base text-[#858585]">
                Description<span className="text-red-500">*</span>
              </label>
              <textarea rows={4}
                className="resize-none text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                type="text"
                placeholder="Description goes here"
                {...register("description", {
                  required: "*This field is required",
                })}
              />
              {errors.description && (
                <div className="text-sm text-red-500">
                  {errors.description.message}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="tag" className="text-sm sm:text-base text-[#858585]">
                Tag
              </label>
              <Select label="Select Tag" size='md'
                className="text-black text-sm sm:text-base placeholder:text-[#D9D9D9] h-[41px]"
                value={tag}
                onChange={(value) => setTag(value)}
              >
                <Option value="new">New</Option>
                <Option value="popular">Popular</Option>
                <Option value="most-brought">Most-Brought</Option>
              </Select>
            </div>
            <div className='flex flex-col xl:flex-row items-center gap-2 xl:gap-4 w-full'>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="numberOfParticipant" className="text-sm sm:text-base text-[#858585]">
                  No. of Participants<span className="text-red-500">*</span>
                </label>
                <input
                  className="text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                  type="number"
                  placeholder="No. of Participants"
                  {...register("numberOfParticipant", {
                    required: "*This field is required",
                  })}
                  onWheel={(e) => e.target.blur()}
                />
                {errors.numberOfParticipant && (
                  <div className="text-sm text-red-500">
                    {errors.numberOfParticipant.message}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="contestPrice" className="text-sm sm:text-base text-[#858585]">
                  Contest Fee<span className="text-red-500">*</span>
                </label>
                <input
                  className="text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                  type="number"
                  placeholder="Contest fee"
                  {...register("contestPrice", {
                    required: "*This field is required",
                  })}
                  onWheel={(e) => e.target.blur()}
                />
                {errors.contestPrice && (
                  <div className="text-sm text-red-500">
                    {errors.contestPrice.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="drawDate" className="text-sm sm:text-base text-[#858585]">
                Expected Draw Date<span className="text-red-500">*</span>
              </label>
              <div className='flex items-center gap-2 py-2 px-4 border border-[#D9D9D9] rounded-md'>
                <Flatpickr
                  className="w-full text-sm sm:text-base placeholder:text-[#D9D9D9] outline-none bg-transparent"
                  type="text"
                  placeholder="Select Date"
                  options={{
                    mode: "single",
                    dateFormat: "d F Y",
                    // minDate: selectDate,
                    disableMobile: true,
                  }}
                  onChange={(selectedDate) => setSelectDate(selectedDate[0])}
                  value={selectDate}
                />
                <Icon icon="ic:round-calendar-month" className='text-2xl text-[#808080]' />
              </div>
              {showError && !selectDate && (
                <div className="text-sm text-red-500">
                  This field is required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="prizeType" className="text-sm sm:text-base text-[#858585]">
                Prize Type<span className="text-red-500">*</span>
              </label>
              <Select label="Select Prize" size='md'
                className="text-black text-sm sm:text-base placeholder:text-[#D9D9D9] h-[41px]"
                value={prizeType}
                onChange={(value) => setPrizeType(value)}
              >
                <Option value="money">Money</Option>
                <Option value="mobile">Mobile</Option>
                <Option value="car">Car</Option>
              </Select>
              {showError && !prizeType && (
                <div className="text-sm text-red-500">
                  This field is required
                </div>
              )}
            </div>
            {
              prizeType === "money" && (
                <div className="flex flex-col gap-1">
                  <label htmlFor="cashPrize" className="text-sm sm:text-base text-[#858585]">
                    Cash Prize<span className="text-red-500">*</span>
                  </label>
                  <input
                    className="text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                    type="number"
                    placeholder="Enter Cash Prize"
                    {...register("cashPrize", {
                      required: prizeType === "money" ? "*This field is required" : false,
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.cashPrize && (
                    <div className="text-sm text-red-500">
                      {errors.cashPrize.message}
                    </div>
                  )}
                </div>
              )
            }
            {
              prizeType === "mobile" && (
                <div className="flex flex-col gap-1">
                  <label htmlFor="phonePrize" className="text-sm sm:text-base text-[#858585]">
                    Phone Prize<span className="text-red-500">*</span>
                  </label>
                  <input
                    className="text-sm sm:text-base py-2 px-4 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                    type="text"
                    placeholder="Enter Phone Prize"
                    {...register("phonePrize", {
                      required: prizeType === "mobile" ? "*This field is required" : false,
                    })}
                  />
                  {errors.phonePrize && (
                    <div className="text-sm text-red-500">
                      {errors.phonePrize.message}
                    </div>
                  )}
                </div>
              )
            }
            {
              prizeType === "car" && (
                <div className="flex flex-col gap-1">
                  <label htmlFor="carPrize" className="text-sm sm:text-base text-[#858585]">
                    Car Prize<span className="text-red-500">*</span>
                  </label>
                  <div className='flex flex-col gap-4 text-sm sm:text-base p-3 border border-[#D9D9D9] rounded-md'>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="carName" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        Car Name :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="text"
                        placeholder="Name"
                        value={carPrize.carName}
                        onChange={(e) => setCarPrize({ ...carPrize, carName: e.target.value })}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="carModel" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        Car Model :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="text"
                        placeholder="Model"
                        value={carPrize.carModel}
                        onChange={(e) => setCarPrize({ ...carPrize, carModel: e.target.value })}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="exShowroomPrice" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        Ex-Showroom Price :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="number"
                        placeholder="Price"
                        value={carPrize.exShowroomPrice}
                        onChange={(e) => setCarPrize({ ...carPrize, exShowroomPrice: e.target.value })}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="RTO" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        RTO :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="number"
                        placeholder="RTO"
                        value={carPrize.RTO}
                        onChange={(e) => setCarPrize({ ...carPrize, RTO: e.target.value })}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="insurance" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        Insurance :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="number"
                        placeholder="Insurance"
                        value={carPrize.insurance}
                        onChange={(e) => setCarPrize({ ...carPrize, insurance: e.target.value })}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="others" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        Others :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="number"
                        placeholder="Others"
                        value={carPrize.others}
                        onChange={(e) => setCarPrize({ ...carPrize, others: e.target.value })}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="onRoadPrice" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        On-Road Price :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="number"
                        placeholder="Price"
                        value={carPrize.onRoadPrice}
                        onChange={(e) => setCarPrize({ ...carPrize, onRoadPrice: e.target.value })}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                    <div className='flex items-center gap-2 '>
                      <label htmlFor="cityName" className="text-sm sm:text-base text-[#858585] block min-w-[10rem]">
                        City Name :
                      </label>
                      <input
                        className='py-[6px] px-2 border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full'
                        type="text"
                        placeholder="City"
                        value={carPrize.cityName}
                        onChange={(e) => setCarPrize({ ...carPrize, cityName: e.target.value })}
                      />
                    </div>
                  </div>
                  {showError && !Object.values(carPrize).every((field) => field !== "") && (
                    <div className="text-sm text-red-500">
                      *All fields are required
                    </div>
                  )}
                </div>
              )
            }
          </div>
          <div className='flex flex-col gap-3 sm:gap-4 flex-1'>
            <div className='flex flex-col gap-1'>
              <label htmlFor="thumbnail" className="text-sm sm:text-base text-[#858585]">
                Thumbnail<span className="text-red-500">*</span>
              </label>
              <div className="flex flex-col items-center border-2 border-dashed border-[#D9D9D9] rounded-lg py-4 px-2">
                {thumbnailImg ? (
                  <>
                    {
                      thumbnailImg.name ? (
                        <img src={URL.createObjectURL(thumbnailImg)} alt="" className='h-[100px] w-[100px] ' />
                      ) : (
                        <img src={thumbnailImg} alt="" className='h-[100px] w-[100px] ' />
                      )
                    }
                    <div className="flex gap-4 items-center mt-[0.5rem]">
                      {
                        thumbnailImg?.name && (
                          <h2 className="text-base text-black font-[500]">
                            {thumbnailImg?.name?.length > 30
                              ? thumbnailImg.name.substring(0, 30) + "..."
                              : thumbnailImg.name}
                          </h2>
                        )
                      }
                      <div className='border border-[#D9D9D9] rounded-[4px] hover:bg-red-300'>
                        <Icon
                          onClick={() => setThumbnailImg("")}
                          icon="akar-icons:cross"
                          className="text-xl cursor-pointer p-1"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      <h2 className='text-primary m-4'>
                        <Icon
                          icon="material-symbols:cloud-upload"
                          className="text-5xl"
                        />
                      </h2>
                    </button>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.svg"
                      value=""
                      className="input-field"
                      hidden
                      onChange={({ target: { files } }) => {
                        if (files[0]) {
                          setThumbnailImg(files[0]);
                        }
                      }}
                    />
                    <p className="text-[13px] text-[#AAAAAA80] text-center">
                      Upload <span className='text-black'> jpg, png, svg </span> Size up to 20MB <span className='text-black'> 366x220 (1:0.6) </span>
                    </p>
                  </>
                )}
              </div>
              {showError && !thumbnailImg && (
                <div className="text-sm text-red-500">
                  *This field is required
                </div>
              )}
            </div>
            <div className='flex flex-col gap-1'>
              <label htmlFor="sliders" className="text-sm sm:text-base text-[#858585]">
                Sliders<span className="text-red-500">*</span>
              </label>
              <div className="flex flex-col items-center border-2 border-dashed border-[#D9D9D9] rounded-lg py-4 px-2">
                <button
                  type="button"
                  onClick={() =>
                    document.querySelector(".input-field-2").click()
                  }
                >
                  <h2 className='text-primary m-4'>
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-5xl"
                    />
                  </h2>
                </button>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png,.svg"
                  value=""
                  className="input-field-2"
                  multiple
                  hidden
                  onChange={addSliderImages}
                />
                <p className="text-[13px] text-[#AAAAAA80] text-center">
                  Upload <span className='text-black'> jpg, png, svg </span> Size up to 20MB <span className='text-black'> 382x220 (1:0.6) </span>
                </p>
              </div>
              {showError && sliderImgs?.length === 0 && (
                <div className="text-sm text-red-500">
                  *This field is required
                </div>
              )}
              {
                sliderImgs.length > 0 && (
                  <div className='max-w-[350px] lg:max-w-[300px] xll:max-w-[400px] p-2 border-2 border-dashed border-[#D9D9D9] rounded-lg mt-2'>
                    <Swiper
                      className='px-2'
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={15}
                      slidesPerView={3}
                      breakpoints={{
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        1390: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                      }}
                      navigation
                      // navigation={{
                      //   nextEl: '.swiper-button-next',
                      //   prevEl: '.swiper-button-prev',
                      // }}
                      pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                    >
                      {
                        sliderImgs?.map((img, index) => (
                          <SwiperSlide key={index}>
                            <div className="flex items-center gap-2 relative">
                              {
                                img.name ? (
                                  <img src={URL.createObjectURL(img)} alt="" className='h-[115px] rounded-lg object-fill' />
                                ) : (
                                  <img src={img} alt="" className='h-[115px] rounded-lg object-fill' />
                                )
                              }
                              <div className='border border-[#D9D9D9] bg-white rounded-[4px] hover:bg-red-300 absolute right-[0px] top-[0px]'>
                                <Icon
                                  onClick={() => removeSliderImages(index)}
                                  icon="akar-icons:cross"
                                  className="text-lg cursor-pointer p-1"
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))
                      }
                      {/* <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide> */}
                      {/* <button className="swiper-button-prev bg-gray-800 text-white rounded-full p-2 absolute top-1/2 left-2 transform -translate-y-1/2 hover:bg-gray-600">
          ❮
        </button>
        <button className="swiper-button-next bg-gray-800 text-white rounded-full p-2 absolute top-1/2 right-2 transform -translate-y-1/2 hover:bg-gray-600">
          ❯
        </button> */}
                    </Swiper>
                    {/* Custom Navigation Buttons */}
                  </div>
                )
              }
            </div>
            <div className='flex flex-col gap-1'>
              <label htmlFor="verticalImg" className="text-sm sm:text-base text-[#858585]">
                Vertical Image<span className="text-red-500">*</span>
              </label>
              <div className="flex flex-col items-center border-2 border-dashed border-[#D9D9D9] rounded-lg py-4 px-2 h-[200px] w-[175px]">
                {verticalImg ? (
                  <>
                    {
                      verticalImg.name ? (
                        <img src={URL.createObjectURL(verticalImg)} alt="" className='h-[125px] w-[100px]' />
                      ) : (
                        <img src={verticalImg} alt="" className='h-[125px] w-[100px] ' />
                      )
                    }
                    <div className="flex items-center flex-wrap mt-2">
                      {
                        verticalImg?.name && (
                          <h2 className="text-base text-black font-[500] overflow-hidden text-ellipsis w-[125px]">
                            {verticalImg?.name?.length > 30
                              ? verticalImg.name.substring(0, 30) + "..."
                              : verticalImg.name}
                          </h2>
                        )
                      }
                      <div className='border border-[#D9D9D9] rounded-[4px] hover:bg-red-300'>
                        <Icon
                          onClick={() => setVerticalImg("")}
                          icon="akar-icons:cross"
                          className="text-xl cursor-pointer p-1"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() =>
                        document.querySelector(".input-field-3").click()
                      }
                    >
                      <h2 className='text-primary m-4'>
                        <Icon
                          icon="material-symbols:cloud-upload"
                          className="text-5xl"
                        />
                      </h2>
                    </button>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.svg"
                      value=""
                      className="input-field-3"
                      hidden
                      onChange={({ target: { files } }) => {
                        if (files[0]) {
                          setVerticalImg(files[0]);
                        }
                      }}
                    />
                    <p className="text-[13px] text-[#AAAAAA80] text-center">
                      Upload <span className='text-black'> jpg, png, svg </span> Size up to 20MB <span className='text-black'> 168x210 (1:1.25) </span>
                    </p>
                  </>
                )}
              </div>
              {showError && !verticalImg && (
                <div className="text-sm text-red-500">
                  *This field is required
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-8 items-center justify-end text-base sm:text-xl mt-10 font-medium">
          <button onClick={() => navigate("/admin/contests")}
            className="text-primary bg-white border border-primary py-[0.4rem] w-48 rounded-md"
            type="button"
          >
            Back
          </button>


          <button
            className="text-white bg-primary py-2 w-48 rounded-md text-center"
            type="submit"
            disabled={loader}
          >
            {loader ? (
              <div className='flex items-center justify-center'>
                <ThreeDots
                  height="30"
                  width="45"
                  radius="8"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : "Update Contest"
            }
          </button>
        </div>
      </form>
    </div>
  )
}


import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '@iconify/react';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Select as MaterialSelect, Option, Radio, Typography } from '@material-tailwind/react';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

export default function EditOffer() {
  const [setUpdateOffersList] = useOutletContext();
  const { id } = useParams();
  const [showError, setShowError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const [offerType, setOfferType] = useState("");
  const [typeOfOffer, setTypeOfOffer] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [contestsData, setContestsData] = useState([]);
  const [selectedContestsId, setSelectedContestsId] = useState([]);
  const [contestSelectOptions, setContestSelectOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedUsersId, setSelectedUsersId] = useState([]);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [comboOfferContestsId, setComboOfferContestsId] = useState([]);
  const [comboOfferContestsOptions, setComboOfferContestsOptions] = useState([]);
  const { getAccessToken, userRole } = useAuth();
  const startDateRef = useRef();
  const expiryDateRef = useRef();
  const navigate = useNavigate();

  //   { value: "1", label: "Option 1", isTypeDiscount: true },
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]); // Array of {value, label, isTypeDiscount, text}
  const [search, setSearch] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const containerRef = useRef(null);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0.1rem 0.3rem',
      border: state.isFocused || state.isHover ? '1px solid #2563EB' : '1px solid #D9D9D9',
      borderRadius: '6px',
      outline: 'none',
      boxShadow: 'none',
      fontSize: '14px',
      '@media (min-width: 640px)': {
        fontSize: '16px',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D9D9D9',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  const fetchSingleOffer = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchOfferById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to fetch single Offer");
        throw new Error("Failed to fetch single Offer");
      }
      const result = await response.json();
      console.log("Offer by Id", result?.response?.offer);
      const { type_offer, typeInfo, contestId, title, coupon_code, totalUsage, userLimit, startDate, expiryDate, terms, userSpecific } = result?.response?.offer;
      setTypeOfOffer(type_offer);
      setSelectedContestsId(contestId.map((contest) => contest._id));
      setContestSelectOptions(contestId.map((contest) => ({ value: contest._id, label: contest.name })));
      // offer type information
      if (type_offer === "DISCOUNT") {
        if (typeInfo?.discountType === "AMOUNT") {
          setOfferType("Amount");
          setAmount(typeInfo?.number);
        } else {
          setOfferType("Percentage");
          setPercentage(typeInfo?.number);
        }
      } else if (type_offer === "COMBO") {
        const [one, two] = typeInfo?.comboType?.split("+");
        setValue("inputOne", one?.trim());
        setValue("inputTwo", two?.trim());
        setComboOfferContestsId(typeInfo?.freeContestId?.map((contest) => contest._id));
        setComboOfferContestsOptions(typeInfo?.freeContestId?.map((contest) => ({ value: contest._id, label: contest.name })));
      } else {
        const [one, two] = typeInfo?.comboType?.split("+");
        setValue("inputOne", one?.trim());
        setValue("inputTwo", two?.trim());
        setSelected(typeInfo?.offerContest.map((contest) => ({
          value: contest?.contestId?._id,
          label: contest?.contestId?.name,
          isTypeDiscount: contest?.discountType === "AMOUNT",
          text: contest.number
        })));
      }
      setValue("title", title);
      setCouponCode(coupon_code);
      setSelectedUsersId(userSpecific.map((user) => user._id));
      setUserSelectOptions(userSpecific.map((user) => ({ value: user._id, label: user.FirstName + " " + user.LastName })));
      setValue("totalLimit", totalUsage);
      setValue("limitForUser", userLimit);
      setStartDate(startDate);
      setExpiryDate(expiryDate);
      setValue("termsConditions", terms);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchContestData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.getContests, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Contest Data");
        throw new Error("Failed to Fetch Contest Data");
      }
      const result = await response.json();
      setContestsData(result?.response?.contests.map((contest) => {
        return {
          value: contest._id,
          label: contest.name,
        }
      }));
      // options for combo offer discount
      setOptions(result?.response?.contests?.map((contest) => {
        return {
          value: contest._id,
          label: contest.name,
          isTypeDiscount: true,
          text: ""
        }
      }))
    } catch (error) {
      console.error("Failed to Fetch Contest Data")
    }
  };
  const fetchUsersData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchUsers, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Users Data in Offers");
        throw new Error("Failed to Fetch Users Data in Offers");
      }
      const result = await response.json();
      setUsersData(result?.response?.Users.reverse().map((user) => {
        return {
          value: user._id,
          label: user.FirstName + " " + user.LastName,
        }
      }));
    } catch (error) {
      console.error("Failed to Fetch Users Data in Offers")
    }
  };
  useEffect(() => {
    fetchContestData();
    fetchUsersData();
  }, [])
  useEffect(() => {
    fetchSingleOffer();
  }, [id]);


  const onSubmit = async (data, event) => {
    const isValidOfferDiscountInput = selected.every((item) => item?.text !== "");
    if (!(typeOfOffer && (typeOfOffer !== "DISCOUNT" || offerType !== "") && selectedContestsId.length !== 0 && (offerType !== "Amount" || amount !== "") && (offerType !== "Percentage" || percentage !== "") && (typeOfOffer !== "COMBO" || comboOfferContestsId.length !== 0) && couponCode && startDate && expiryDate && (typeOfOffer !== "COMBO-DISCOUNT" || (selected.length !== 0 && isValidOfferDiscountInput)))) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    try {
      setLoaderBtn(true)
      const { title, totalLimit, limitForUser, termsConditions, inputOne, inputTwo } = data;
      const token = await getAccessToken()
      const response = await fetch(`${APIurls.updateOfferById}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          "title": title,
          "coupon_code": couponCode,
          "contestId": selectedContestsId,
          "terms": termsConditions,
          "type_offer": typeOfOffer,
          "userLimit": Number(limitForUser),
          "totalUsage": Number(totalLimit),
          "startDate": startDate,
          "expiryDate": expiryDate,
          "userSpecific": selectedUsersId,
          "contestSpecific": selectedContestsId.length > 0,
          "isUserSpecific": selectedUsersId.length > 0,
          "typeInfo": typeOfOffer === "DISCOUNT" ? {
            discountType: offerType?.toUpperCase(),
            number: offerType === "Amount" ? amount : percentage
          } : typeOfOffer === "COMBO" ? {
            comboType: inputOne + " + " + inputTwo,
            freeContestId: comboOfferContestsId
          } : {
            comboType: inputOne + " + " + inputTwo,
            offerContest: selected.map((item) => {
              return {
                contestId: item.value,
                discountType: item.isTypeDiscount ? "AMOUNT" : "PERCENTAGE",
                number: item.text
              }
            })
          }
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error updating Offer:", result.message);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error updating Offer");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Updated Offer!");
        setUpdateOffersList(true);
        navigate("/admin/offers-banners")
        console.log("Update Offer result: ", result);
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error updating Offer")
      console.warn(error);
    }
  }

  const handleStartDate = (selectedDate) => {
    setStartDate(selectedDate[0]);
  };
  const handleExpiryDate = (selectedDate) => {
    setExpiryDate(selectedDate[0]);
  }
  const handleRadioBtnChange = (e) => {
    setOfferType(e.target.value);
  }

  const handleMultiContestSelect = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedContestsId(values);
    setContestSelectOptions(selectedOptions);
  }
  const handleMultiUsersSelect = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedUsersId(values);
    setUserSelectOptions(selectedOptions)
  }
  const handleComboOfferContestSelect = (selectedOptions) => {
    const value = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setComboOfferContestsId(value);
    setComboOfferContestsOptions(selectedOptions);
  }

  const generateCouponCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$&';
    let code = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
    return code;
  }

  const generateCouponCodeHandler = () => {
    const code = generateCouponCode();
    setCouponCode(code);
  }


  // custom select for Combo Offer Discount
  const handleSelect = (option) => {
    if (!selected.some((item) => item.value === option.value)) {
      setSelected([...selected, { ...option, text: "" }]);
    }
    setSearch(""); // Clear search after selection
  };

  // Handle message input for selected items
  const handleTextChange = (value, text) => {
    setSelected((prevSelected) =>
      prevSelected.map((item) =>
        item.value === value ? { ...item, text } : item
      )
    );
  };
  const toggleSelectedInputTypeChange = (value, e) => {
    e.stopPropagation();
    setSelected((prevSelected) =>
      prevSelected.map((item) =>
        item.value === value ? { ...item, isTypeDiscount: !item.isTypeDiscount, text: "" } : item
      )
    )
  }
  // Handle clearing a single selection
  const handleRemove = (value, e) => {
    e.stopPropagation();
    setSelected((prevSelected) =>
      prevSelected.filter((item) => item.value !== value)
    );
  };
  // Handle clearing all selections
  const handleClearAll = () => {
    setSelected([]);
    setSearch("");
  };
  // Filter options based on search and exclude selected items
  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(search.toLowerCase().trim()) &&
        !selected.some((item) => item.value === option.value)
    );
  }, [options, search, selected]);

  // Handle click outside to hide the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="flex flex-col gap-4 text-sm sm:text-base">
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-4 px-0 sm:max-xl:px-6 xl:px-8 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 xl:gap-x-16 gap-y-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="typeOfOffer" className="text-[#858585]">
              Type of Offer
            </label>
            <MaterialSelect className='text-black text-sm sm:text-base'
              label='Select'
              size='md'
              color='blue-gray'
              variant='outlined'
              value={typeOfOffer}
              onChange={(value) => setTypeOfOffer(value)}
            >
              <Option value='DISCOUNT'>Discount</Option>
              <Option value='COMBO'>Combo Offers</Option>
              <Option value='COMBO-DISCOUNT'>Combo Discounts Offer</Option>
            </MaterialSelect>
            {showError && !typeOfOffer && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          {/* Type of Offer ==> Discount */}
          {
            typeOfOffer === "DISCOUNT" && (
              <>
                <div className='flex flex-col gap-1'>
                  <label htmlFor="offerType" className="text-[#858585]">
                    Offer Type
                  </label>
                  <div className='flex items-center justify-between gap-10 w-full'>
                    <div className='max-h-[40px] flex items-center border border-[#D9D9D9] w-full rounded-md px-2'>
                      <Radio name="offerType"
                        color='green'
                        label={
                          <Typography
                            // color="#858585"
                            className={`font-medium ${offerType === "Amount" ? "text-black" : "text-[#858585]"} ml-2`}
                          >
                            Amount
                          </Typography>
                        }
                        value="Amount"
                        checked={offerType === "Amount"}
                        onChange={handleRadioBtnChange}
                      />
                    </div>
                    <div className='max-h-[40px] flex items-center border border-[#D9D9D9] w-full rounded-md px-2'>
                      <Radio name="offerType"
                        color='green'
                        label={
                          <Typography
                            // color="#858585"
                            className={`font-medium ${offerType === "Percentage" ? "text-black" : "text-[#858585]"} ml-2`}
                          >
                            Percentage
                          </Typography>
                        }
                        value="Percentage"
                        checked={offerType === "Percentage"}
                        onChange={handleRadioBtnChange}
                      />
                    </div>
                  </div>
                  {showError && !offerType && (
                    <div className="text-sm text-[#E92215]">
                      *Please select anyone of the above
                    </div>
                  )}
                </div>
                {
                  offerType !== "" && (
                    offerType === "Amount" ?
                      (<div className='flex flex-col gap-1'>
                        <label htmlFor="discount" className="text-[#858585]">
                          Discount Amount
                        </label>
                        <input
                          className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                          type="number"
                          placeholder="Enter Discount Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        {showError && !amount && (
                          <div className="text-sm text-[#E92215]">
                            *This field is required
                          </div>
                        )}
                      </div>)
                      :
                      (<div className='flex flex-col gap-1'>
                        <label htmlFor="percentage" className="text-[#858585]">
                          Discount Percentage
                        </label>
                        <input
                          className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                          type="number"
                          placeholder="Enter Discount Percentage"
                          value={percentage}
                          onChange={(e) => setPercentage(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        {showError && !percentage && (
                          <div className="text-sm text-[#E92215]">
                            *This field is required
                          </div>
                        )}
                        {percentage > 100 && (
                          <div className="text-sm text-[#E92215]">
                            *Note: Percentage can't be greater than 100
                          </div>
                        )}
                      </div>)
                  )
                }
              </>
            )
          }
          {/* Type of Offer => Combo */}
          {
            (typeOfOffer === "COMBO" || typeOfOffer === "COMBO-DISCOUNT") && (
              <div className='flex flex-col gap-1'>
                <label htmlFor="discountType" className="text-[#858585]">
                  Discount Type
                </label>
                <div className='flex items-center justify-between w-full gap-4 xl:gap-6'>
                  <input
                    className="py-[0.45rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full"
                    type="number"
                    placeholder="Input One"
                    {...register("inputOne", {
                      required: true,
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  <span className='text-2xl'> + </span>
                  <input
                    className="py-[0.45rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none w-full"
                    type="number"
                    placeholder="Input two"
                    {...register("inputTwo", {
                      required: true,
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                {(errors.inputOne || errors.inputTwo) && (
                  <div className="text-sm text-[#E92215]">
                    *Please select both fields
                  </div>
                )}
              </div>
            )
          }
          <div className='flex flex-col gap-1'>
            <label htmlFor="selectContest" className="text-[#858585]">
              Select Contest
            </label>
            <Select
              isMulti
              name="contest"
              placeholder="Select"
              options={contestsData}
              styles={customStyles}
              value={contestSelectOptions}
              className="outline-none"
              classNamePrefix="select"
              onChange={handleMultiContestSelect}
            />
            {showError && selectedContestsId.length === 0 && (
              <div className="text-sm text-[#E92215]">
                *This field is required
              </div>
            )}
          </div>
          {/* Type of Offer => Combo */}
          {
            typeOfOffer === "COMBO" && (
              <div className='flex flex-col gap-1'>
                <label htmlFor="comboOfferContest" className="text-[#858585]">
                  Select Combo Offer Contest
                </label>
                <Select
                  isMulti
                  name="comboOfferContest"
                  placeholder="Select"
                  options={contestsData}
                  styles={customStyles}
                  value={comboOfferContestsOptions}
                  className="outline-none"
                  classNamePrefix="select"
                  onChange={handleComboOfferContestSelect}
                />
                {showError && comboOfferContestsId.length === 0 && (
                  <div className="text-sm text-[#E92215]">
                    *This field is required
                  </div>
                )}
              </div>
            )
          }
          {/* Type of Offer => Combo Offer Discount */}
          {
            typeOfOffer === "COMBO-DISCOUNT" && (
              <div className="flex flex-col gap-1">
                <label htmlFor="comboOfferDiscount" className="text-[#858585]">
                  Select Combo Offer Discount
                </label>
                <div ref={containerRef} className='relative'>
                  <div className={`${selected.length === 0 && "flex"} border border-[#D9D9D9] hover:border-black/40 rounded-md px-3 py-2 cursor-default`}
                    onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  >
                    {/* Input for search and display selected */}
                    {
                      selected.length > 0 && (
                        <div className='flex justify-between items-start gap-6'>
                          <div className='flex flex-col gap-1 text-sm w-full'>
                            {selected.map((item) => (
                              <div key={item.value}
                                className='flex items-center gap-1 bg-[#e6e6e6] py-[2px] px-[4px]'>
                                <span className='w-full ml-1'>{item.label}</span>
                                <div className='flex justify-between items-center border border-[#D9D9D9] bg-white'>
                                  <input
                                    className='w-[130px] outline-none bg-transparent p-[4px] rounded-[4px]'
                                    type="text"
                                    value={item.text}
                                    placeholder={item.isTypeDiscount ? "₹" : "%"}
                                    onChange={(e) => handleTextChange(item.value, e.target.value)}
                                    // onFocus={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <button type='button' onClick={(e) => toggleSelectedInputTypeChange(item.value, e)}>
                                    {
                                      item?.isTypeDiscount ? (
                                        <Icon icon="ic:outline-currency-rupee"
                                          className='text-xl bg-primary text-white rounded-[4px] px-1 mr-1' />
                                      ) : (
                                        <Icon icon="ic:outline-percentage"
                                          className='text-xl bg-primary text-white rounded-[4px] px-1 mr-1' />
                                      )
                                    }
                                  </button>
                                </div>
                                <button type='button' onClick={(e) => handleRemove(item.value, e)}
                                  className='text-black/50 hover:bg-red-200 transition-all duration-100'>
                                  <Icon icon="charm:cross" className='text-base' />
                                </button>
                              </div>
                            ))}
                          </div>
                          <div className='flex items-center gap-2'>
                            {
                              selected?.length > 0 && (
                                <button type='button' onClick={handleClearAll}
                                  className='text-black/50 hover:text-black transition-all duration-100'>
                                  <Icon icon="charm:cross" className='text-xl' />
                                </button>
                              )
                            }
                            <span className='h-6 w-[1px] bg-[#D9D9D9]'></span>
                            <button type='button' className='text-black/50 hover:text-black transition-all duration-100'>
                              <Icon icon="charm:chevron-down" className='text-xl' />
                            </button>
                          </div>
                        </div>
                      )
                    }
                    <input
                      className='w-full border-none outline-none cursor-default'
                      type="text"
                      placeholder="Select"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {
                      selected.length === 0 && (
                        <div className='flex justify-end gap-2'>
                          <span className='h-6 w-[1px] bg-[#D9D9D9]'></span>
                          <button type='button' className='text-black/50 hover:text-black transition-all duration-100'>
                            <Icon icon="charm:chevron-down" className='text-xl' />
                          </button>
                        </div>
                      )
                    }
                  </div>

                  {/* Dropdown options */}
                  {isDropdownVisible && (
                    <div className='border border-[#D9D9D9] mt-1 rounded-md absolute z-50 w-full bg-white max-h-72 overflow-y-auto py-1'
                      style={{
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)"
                      }}
                    >
                      {
                        filteredOptions.length > 0 ? (
                          filteredOptions.map((option) => (
                            <div className='hover:bg-[#deebff] cursor-pointer py-[8px] px-[10px]'
                              key={option.value}
                              onClick={() => handleSelect(option)}
                            >
                              {option.label}
                            </div>
                          ))
                        ) : (
                          <p className='text-[#858585] text-center p-4'>No Options</p>
                        )
                      }
                    </div>
                  )}
                </div>
                {
                  showError && selected.length === 0 && (
                    <p className="text-sm text-[#E92215]">
                      *This field is required
                    </p>
                  )
                }
                {
                  showError && selected.length !== 0 && selected.some((item) => item?.text?.trim() === "") && (
                    <p className="text-sm text-[#E92215]">
                      *Text field in all selected options are required
                    </p>
                  )
                }
              </div>
            )
          }

          <div className="flex flex-col gap-1">
            <label htmlFor="title" className="text-[#858585]">
              Title
            </label>
            <input
              className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Title"
              {...register("title", {
                required: "*This field is required",
              })}
            />
            {errors.title && (
              <div className="text-sm text-[#E92215]">
                {errors.title.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="couponCode" className="text-[#858585]">
              Coupon Code
            </label>
            <div className='flex justify-between items-center py-[5px] px-[1rem] border border-[#D9D9D9] rounded-md'>
              <input
                className="placeholder:text-[#D9D9D9] outline-none"
                type="text"
                placeholder="Enter Coupon Code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={generateCouponCodeHandler}
                type='button'
                className='text-primary text-xs sm:text-sm border-2 border-primary rounded-[4px] py-[2.5px] px-[0.5rem]'>
                Auto Generate Code
              </button>
            </div>
            {showError && !couponCode && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="specificUsers" className="text-[#858585]">
              Specific Users (Optional)
            </label>
            <Select
              isMulti
              name="users"
              placeholder="Select"
              options={usersData}
              styles={customStyles}
              value={userSelectOptions}
              className="outline-none"
              classNamePrefix="select"
              onChange={handleMultiUsersSelect}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="totalLimit" className="text-[#858585]">
              Total Limit
            </label>
            <input
              className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Total Limit"
              {...register("totalLimit", {
                required: "*This field is required",
              })}
              onWheel={(e) => e.target.blur()}
            />
            {errors.totalLimit && (
              <div className="text-sm text-[#E92215]">
                {errors.totalLimit.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="limitForUser" className="text-[#858585]">
              Limit For User
            </label>
            <input
              className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Limit For User"
              {...register("limitForUser", {
                required: "*This field is required",
              })}
              onWheel={(e) => e.target.blur()}
            />
            {errors.limitForUser && (
              <div className="text-sm text-[#E92215]">
                {errors.limitForUser.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="startDate" className="text-[#858585]">
              Start Date
            </label>
            <Flatpickr
              ref={startDateRef}
              className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder={`Select Start Date`}
              options={{
                mode: "single",
                dateFormat: "d-m-Y",
                // minDate: Date.now(),
                // maxDate: expiryDate ? expiryDate : "",
              }}
              value={startDate}
              onChange={(selectedDate) => handleStartDate(selectedDate)}
            />
            {showError && !startDate && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="expiryDate" className="text-[#858585]">
              Expiry Date
            </label>
            <Flatpickr
              ref={expiryDateRef}
              className="py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder={`Select Expiry Date`}
              options={{
                mode: "single",
                dateFormat: "d-m-Y",
                // minDate: startDate ? startDate : Date.now(),
              }}
              value={expiryDate}
              onChange={(selectedDate) => handleExpiryDate(selectedDate)}
            />
            {showError && !expiryDate && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="termsConditions" className="text-[#858585]">
              Terms & Conditions
            </label>
            <textarea rows={4}
              className="resize-none py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Terms & Conditions"
              {...register("termsConditions", {
                required: "*This field is required",
              })}
            />
            {errors.termsConditions && (
              <div className="text-sm text-[#E92215]">
                {errors.termsConditions.message}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mt-[1rem] text-base sm:text-xl font-medium">
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("CREATE_OFFERS")) && (
            <button
              className="text-white bg-primary py-2 rounded-md text-center w-44"
              type="submit"
              disabled={loaderBtn}>
              {loaderBtn ? (
                <div className='flex items-center justify-center'>
                  <ThreeDots
                    height="30"
                    width="45"
                    radius="8"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : "Update Offer"
              }
            </button>
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}


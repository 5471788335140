let baseUrl = "http://localhost:4000";

const type = "STAGE";
// const type = "PROD";

if (type === "PROD") {
    baseUrl = "https://api.strikexgaming.com"
}
else if (type === "STAGE") {
    baseUrl = "https://stagingapiv2.strikexgaming.com"
}
else {
    baseUrl = "http://localhost:4000"
}

export const APIurls = {
    fetchUsersCount: `${baseUrl}/api/v2/getUsersCount`,
    fetchTotalAmount: `${baseUrl}/api/v2/admin/getTotalAmount`,
    fetchCustomerOrders: `${baseUrl}/api/v2/admin/getCustomerOrders`,

    createContest: `${baseUrl}/api/v2/create-contest`,
    getContests: `${baseUrl}/api/v2/view-contest`,
    getContestById: `${baseUrl}/api/v2/view-contest`,
    updateContest: `${baseUrl}/api/v2/update-contest`,
    deleteContest: `${baseUrl}/api/v2/delete-contest`,
    getContestUsers: `${baseUrl}/api/v2/contest-purchased-user`,
    getContestWishlist: `${baseUrl}/api/v2/contest-wishlist`,
    getContestWinner: `${baseUrl}/api/v2/contest-winner`,

    getArchivedContests: `${baseUrl}/api/v2/get-delete-contest`,
    restoreArchivedContest: `${baseUrl}/api/v2/retrieve-delete-contest`,

    fetchLottary: `${baseUrl}/api/v2/fetch-lottary`,
    createLottary: `${baseUrl}/api/v2/create-lottary`,
    fetchLottaryById: `${baseUrl}/api/v2/single-lottary`,
    editLottary: `${baseUrl}/api/v2/edit-lottary`,
    deleteLottery: `${baseUrl}/api/v2/archive-lottary`,
    fetchLotteryPurchaseHistory: `${baseUrl}/api/v2/get-purchase-history`,
    announceWinner: `${baseUrl}/api/v2/announce-winner`,
    announceContestWinner: `${baseUrl}/api/v2/announce-contest-winner`,
    archiveLottery: `${baseUrl}/api/v2/archive-lottary`,

    getRoles: `${baseUrl}/api/v2/get-roles`,
    fetchUsers: `${baseUrl}/api/v2/fetch-users`,
    fetchUserById: `${baseUrl}/api/v2/fetch-single-user`,
    disableUser: `${baseUrl}/api/v2/disable-users`,
    fetchWalletHistory: `${baseUrl}/api/v2/getWalletHistory`,
    addCoinsToWallet: `${baseUrl}/api/v2/addAmtToWallet`,
    fetchUserWinnnings: `${baseUrl}/api/v2/fetch-winners`,
    fetchUserTransactions: `${baseUrl}/api/v2/fetch-transactios`,
    sendNotificationToSpecificUsers: `${baseUrl}/api/v2/specific-user/notification`,
    // inviteUsers: `${baseUrl}/api/v2/create-invite`,

    createAgent: `${baseUrl}/api/v2/create-agent`,
    fetchAgent: `${baseUrl}/api/v2/fetch-all-agents-private`,
    fetchAgentById: `${baseUrl}/api/v2/fetch-agent-details`,
    disableAgent: `${baseUrl}/api/v2/disable-agents`,
    editAgent: `${baseUrl}/api/v2/edit/agent`,
    updateLotteryAgentStatus: `${baseUrl}/api/v2/disable-agents`,

    fetchAgentRequests: `${baseUrl}/api/v2/agent-req-fetch`,
    approveAgentRequest: `${baseUrl}/api/v2/approve/agent`,
    rejectAgentRequest: `${baseUrl}/api/v2/reject/agent`,

    fetchAllWalletAgents: `${baseUrl}/api/v2/fetch-all-walletagents`,
    fetchWalletAgentById: `${baseUrl}/api/v2/fetch-single-agent`,
    createWalletAgent: `${baseUrl}/api/v2/create-walletagent`,
    editWalletAgent: `${baseUrl}/api/v2/admin/edit-wallet-agent`,
    fetchWalletAgentRechargeHistory: `${baseUrl}/api/v2/fetch-all-wallet-history`,
    addCoinsToWalletAgent: `${baseUrl}/api/v2/add-coins`,
    updateWalletAgentStatus: `${baseUrl}/api/v2/admin/update-wallet-agent-status`,

    fetchUserWalletAgentRequests: `${baseUrl}/api/v2/admin/fetch-user-wallet-request`,
    updateUserWalletAgentRequest: `${baseUrl}/api/v2/admin/update-user-wallet-agent-status`,

    fetchWalletAgentDepositRequest: `${baseUrl}/api/v2/fetch-agent-deposit-requests`,
    updateWalletAgentDepositRequestStatus: `${baseUrl}/api/v2/update-deposit-request-status`,

    fetchPrivateLottary: `${baseUrl}/api/v2/fetch-agents-private-lottary`,
    fetchSinglePrivateLottary: `${baseUrl}/api/v2/single-lottary-private`,
    createPrivateLottary: `${baseUrl}/api/v2//make-private-lottary`,
    editPrivateLottary: `${baseUrl}/api/v2/edit-private-lottary`,
    deletePrivateLottary: `${baseUrl}/api/v2/archive-private-lottary`,
    inviteUsers: `${baseUrl}/api/v2/create-invite`,
    announcePrivateLotteryWinner: `${baseUrl}/api/v2/announce-private-lottery-winner`,
    archivePrivateLottery: `${baseUrl}/api/v2/archive-private-lottary`,

    fetchUserWithdrawalRequests: `${baseUrl}/api/v2/getWithdrawCoinsWithStatus`,
    fetchTotalUserWithdrawals: `${baseUrl}/api/v2/getWithdrawCoinsList`,
    updateUserWithdrawalStatus: `${baseUrl}/api/v2/updateWithDrawCoinStatus`,

    crateSubAdmin: `${baseUrl}/api/v2/create-subadmin`,
    fetchSubadmins: `${baseUrl}/api/v2/subadmins/data`,
    fetchSubadminsById: `${baseUrl}/api/v2/subadmins`,
    editSubadmin: `${baseUrl}/api/v2/subadmin/edit`,
    getSubAdminRoles: `${baseUrl}/api/v2/roles`,
    deleteSubadmin: `${baseUrl}/api/v2/delete-sub-admin`,

    fetchOffers: `${baseUrl}/api/v2/admin/addoffers/fetch`,
    createOffers: `${baseUrl}/api/v2/admin/addoffers`,
    fetchOfferById: `${baseUrl}/api/v2/fetch/offer`,
    updateOfferById: `${baseUrl}/api/v2/update/offer`,
    deleteOffer: `${baseUrl}/api/v2/delete/offer`,

    fetchBanners: `${baseUrl}/api/v2/banner/all`,
    createBanner: `${baseUrl}/api/v2/add/banner`,
    fetchBannerById: `${baseUrl}/api/v2/get/banner`,
    updateBannerById: `${baseUrl}/api/v2/update/banner`,
    deleteBanner: `${baseUrl}/api/v2/banner/delete`,

    fetchNotifications: `${baseUrl}/api/v2/notifications/all`,
    createNotification: `${baseUrl}/api/v2/add/notification`,
    deleteNotification: `${baseUrl}/api/v2/notification/delete`,

    fetchArchiveLotteries: `${baseUrl}/api/v2/fetch-archive-lottary`,
    retrieveArchivedLottery: `${baseUrl}/api/v2/retrive-lottary`,
    fetchArchivePrivateLotteries: `${baseUrl}/api/v2/fetch-archived-priv-lottery`,
    retrieveArchivedPrivateLotteries: `${baseUrl}/api/v2/retrive-private-lottary`,

    fetchRoles: `${baseUrl}/api/v2/roles`,
    fetchDetails: `${baseUrl}/api/v2/fetch-role-details`,
}

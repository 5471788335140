import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList
} from "@material-tailwind/react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { customStyles } from "../../../helpers/customStyles.js";

export default function OffersList({ updateOffersList, setUpdateOffersList }) {
  const { id } = useParams();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchKey);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  const flatpickrRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getAccessToken, userRole } = useAuth();

  const columns = [
    {
      name: "SL",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      center: true,
      width: "70px"
    },
    {
      name: "Offer Type",
      id: "offerType",
      selector: (row) => row.type_offer,
      center: true,
      width: "175px"
    },
    {
      name: "Discount Type",
      id: "discountType",
      selector: (row) => (
        <div>
          {row.type_offer === "DISCOUNT" ? (
            <span>{row?.typeInfo?.discountType} : {row?.typeInfo?.number}</span>
          ) : row.type_offer === "COMBO" ? (
            <span>{row?.typeInfo?.comboType}</span>
          ) : (
            <span>{row?.typeInfo?.comboType}</span>
          )}
        </div>
      ),
      center: true,
      width: "175px"
    },
    {
      name: "Title",
      id: "title",
      selector: (row) => row.title,
      center: true,
      width: "225px"
    },
    {
      name: "Total Limit",
      id: "totalLimit",
      selector: (row) => row.totalUsage,
      center: true,
      width: "125px"
    },
    {
      name: "User Limit",
      id: "userLimit",
      selector: (row) => row.userLimit,
      center: true,
      width: "125px"
    },
    {
      name: "Duration",
      id: "duration",
      selector: (row) => (
        <div className="flex flex-col gap-1">
          <span>{moment(row.startDate).format("DD - MM - YYYY")} /</span>
          <span>{moment(row.expiryDate).format("DD - MM - YYYY")} </span>
        </div>
      ),
      center: true,
      width: "175px"
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`capitalize flex justify-center items-center gap-2 rounded-md font-medium w-[7.5rem] py-2 ${row.status === "ENABLED" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "ENABLED" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-2 w-2 rounded-full`}></span>
          <h2>{row.status?.toLowerCase()}</h2>
        </div>
      ),
      center: true,
      width: "170px"
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-3">
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("EDIT_LOTTERY")) && (
              <Link to={`edit/${row._id}`}>
                <IconButton
                  className={`shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-md border border-[#D9D9D9] ${id === row._id && "text-blue-600 cursor-auto border-blue-200"}`}>
                  <Icon
                    icon="material-symbols:edit-rounded"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              </Link>
            )
          }
          {
            loadingStates[row._id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => deleteOfferHandler(row._id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-md border border-[#D9D9D9]">
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )}
        </div>
      ),
      center: true,
      width: "175px"
    },
  ];

  const fetchOffers = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchOffers}?page=${currentPage}&limit=${rowsPerPage}&title=${debouncedSearch}&startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Offers");
      }
      const result = await response.json();
      console.log("Offers", result?.response)
      return result?.response;
    } catch (error) {
      throw new Error("Failed to Fetch Offers");
    }
  };
  const { data: offersData, isLoading, isError, error, refetch } = useQuery(
    ['offers', currentPage, rowsPerPage, debouncedSearch, startDate, endDate],
    fetchOffers
  );

  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchKey);
    }, 1000);

    // Clean up timer if the search term changes
    return () => clearTimeout(handler);
  }, [searchKey]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const clearDatesHandler = () => {
    setStartDate("");
    setEndDate("");
    flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
  }

  if (updateOffersList) {
    refetch();
    setUpdateOffersList(false);
  }

  const deleteOfferHandler = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete this Offer ?");
    if (isConfirm) {
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        const token = await getAccessToken()
        const response = await fetch(`${APIurls.deleteOffer}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        if (!response.ok) {
          throw new Error("Failed to Delete Offer");
        }
        const result = await response.json();
        console.log(result);
        toast.success("Offer Deleted Successfully");
        refetch();
      } catch (error) {
        console.warn("Failed to Delete Offer");
        toast.error("Failed to Delete Offer");
      } finally {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }
    }
  }

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Contest List...!
        </h1>
      </div>
    )
  }

  return (
    <div className="">
      <div className="text-sm sm:text-base flex flex-col max-xs:items-start xs:flex-row gap-2 justify-between items-center flex-wrap pb-4">
        <h1 className="text-lg font-medium">
          Manage Offers
        </h1>
        <div className="flex gap-4 items-center flex-wrap">
          <div
            className="flex flex-row gap-2 py-2 px-4 w-[300px] lg:w-[350px] max-sm:w-full"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by title"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex items-center justify-between gap-2 py-2 px-4 max-sm:w-full"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Offer Start Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex flex-row gap-3 xs:gap-4 items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-sm sm:text-base flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-2 xs:px-4 py-2 rounded-md items-center gap-2 sm:gap-4 hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    {" "}
                    <Icon
                      icon="fa-solid:file-pdf"
                      className="text-[#BE0A23] text-[1.3rem]"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] text-[1.3rem]"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={offersData?.offers}
          customStyles={customStyles}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={offersData?.pagination?.totalOffers || 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange} />
      </div>
    </div>
  );
}


import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList
} from "@material-tailwind/react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast } from "react-toastify";
import { customStyles } from "../../../../helpers/customStyles.js";
import { IconButton } from "rsuite";

export default function Wishlist() {
  const { id } = useParams();
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchKey);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getAccessToken } = useAuth();

  const columns = [
    {
      name: "SL",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      center: true,
      width: "100px"
    },
    {
      name: "Customer Name",
      id: "customerName",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      width: "250px"
    },
    {
      name: "Date of Wishlist",
      id: "dateOfWishlist",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      width: "175px"
    },
    {
      name: "Price",
      id: "price",
      selector: (row) => "NA",
      center: true,
      width: "175px"
    },
    // {
    //   name: "Status",
    //   id: "status",
    //   selector: (row) => (
    //     <div className={`capitalize flex justify-center items-center gap-2 rounded-md font-medium w-[7.5rem] py-2 ${row.status === "ENABLED" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
    //       <span className={`${row.status === "ENABLED" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-2 w-2 rounded-full`}></span>
    //       <h2>NA</h2>
    //     </div>
    //   ),
    //   center: true,
    //   width: "170px"
    // },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <IconButton
          className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[##858585] rounded-md border border-[#D9D9D9] p-[6px]">
          <Icon icon="ic:round-notifications-active" className="text-[1.5rem]" />
        </IconButton>
      ),
      center: true,
      width: "125px"
    },
  ];

  const fetchContestWishlist = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.getContestWishlist}/${id}?page=${currentPage}&limit=${rowsPerPage}&search=${debouncedSearch}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Contest Wishlist");
      }
      const result = await response.json();
      console.log("Contest Wishlist", result?.response)
      return result?.response;
    } catch (error) {
      throw new Error("Failed to Fetch Contest Wishlist");
    }
  };
  const { data: offersData, isLoading, isError, error } = useQuery(
    ['contestWishlist', id, currentPage, rowsPerPage, debouncedSearch],
    fetchContestWishlist
  );

  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchKey);
    }, 1000);

    return () => clearTimeout(handler);
  }, [searchKey]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Contest Wishlist...!
        </h1>
      </div>
    )
  }

  return (
    <div className="">
      <div className="text-sm sm:text-base flex flex-col max-xs:items-start xs:flex-row gap-2 justify-between items-center flex-wrap pb-4">
        <h1 className="text-lg sm:text-xl font-medium">
          Wishlist
        </h1>
        <div className="flex gap-4 items-center flex-wrap">
          <div
            className="flex flex-row gap-2 py-2 px-4 w-[300px] lg:w-[350px] max-sm:w-full"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Customer Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex flex-row gap-3 xs:gap-4 items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-sm sm:text-base flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-2 xs:px-4 py-2 rounded-md items-center gap-2 sm:gap-4 hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    {" "}
                    <Icon
                      icon="fa-solid:file-pdf"
                      className="text-[#BE0A23] text-[1.3rem]"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] text-[1.3rem]"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={offersData?.wishlist}
          customStyles={customStyles}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={offersData?.totalWishList || 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange} />
      </div>
    </div>
  );
}


import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import moment from 'moment';
import { formatToIndianCurrency } from '../../../helpers/numberConversion';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
} from "@material-tailwind/react";
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';

export default function ViewPublicContest() {
  const location = useLocation();
  const contest = location.state;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = useAuth();

  const handleOpen = () => setOpen(!open);

  const restoreContestHandler = async () => {
    setLoading(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.restoreArchivedContest}/${contest?._id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      const result = await response.json();
      if (!response.ok) {
        toast.error("Failed to restore contest");
        throw new Error("Failed to restore contest");
      }
      console.log(result);
      toast.success("Contest restored Successfully");
      navigate("/admin/archive-contests");
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='flex flex-col gap-6 p-4'>
      <div className='flex justify-between items-start gap-2 text-sm'>
        <button
          onClick={() => navigate("/admin/archive-contests")}
          className="flex items-center gap-2 font-medium text-white bg-primary py-[0.4rem] px-4 rounded-md"
        >
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
          <span>Back</span>
        </button>
        <button onClick={restoreContestHandler}
          disabled={loading}
          className="font-medium text-white bg-primary py-[6.6px] xs:py-2 px-6 rounded-md whitespace-nowrap"
        >
          {loading ? (
            <div className='flex items-center justify-center px-6'>
              <ThreeDots
                height="20"
                width="40"
                radius="8"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : "Restore Contest"
          }
        </button>
      </div>
      <div className='sm:h-[155px] flex max-sm:flex-col flex-row items-center gap-4 sm:gap-6 overflow-x-auto p-1'>
        <img src={contest?.horizontalThumbnail} alt="contestImg" className='rounded-lg max-h-[140px] w-[150px] bg-cover bg-center' />
        <div className='flex flex-col justify-between gap-4 py-2 px-3 rounded-lg min-h-[130px] max-sm:w-full '
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className='flex gap-6 items-center max-sm:w-full max-sm:justify-between max-sm:flex-wrap'>
            <div className='bg-[#D1F0CF] p-1 rounded-md'>
              <Icon icon="game-icons:ticket" className='text-primary text-2xl' />
            </div>
            <div>
              <h1 className='font-semibold whitespace-nowrap text-base sm:text-lg'>
                {contest?.name}
              </h1>
              <p className='text-[#8B8D97] text-xs whitespace-nowrap'>Created on{" "}
                <span className='font-semibold'>
                  {moment(contest.createdAt).format("DD MMM YYYY")}
                </span>
              </p>
            </div>
            <div className={`flex justify-center items-center gap-2 rounded-md font-normal text-sm px-2 py-1 ${contest?.status === "PENDING" ? "text-[#FFCE06] bg-[#FDF9EF]" : "text-[#25BF17] bg-[#D1F0CF]"} capitalize`}>
              <span className={`${contest?.status === "PENDING" ? "bg-[#FFCE06]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
              <h2>{contest?.status?.toLowerCase()}</h2>
            </div>
          </div>
          <div className='flex flex-col gap-1 text-sm'>
            <label htmlFor="emailId" className='text-[#8B8D97] text-xs xs:text-sm'>
              Expected Date Of Draw
            </label>
            <div className='flex items-center gap-[1rem]'>
              <Icon icon="material-symbols:date-range-outline" className='text-2xl' />
              <h2 className='font-medium'>
                {moment(contest?.probablyAnnounceDate).format("DD MMMM YYYY")}
              </h2>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-y-4 gap-x-8 py-2 px-3 rounded-lg min-h-[130px] sm:shrink-0 max-sm:w-full'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div>
            <label htmlFor="totalParticipants" className='text-[#8B8D97] text-xs xs:text-sm'>
              Total Participants
            </label>
            <h2 className='font-medium'>{contest?.numberOfParticipant}</h2>
          </div>
          <div>
            <label htmlFor="contestFee" className='text-[#8B8D97] text-xs xs:text-sm'>
              Contest Fee
            </label>
            <h2 className='font-medium'>
              {formatToIndianCurrency(contest?.contestPrice)}
            </h2>
          </div>
          <div>
            <label htmlFor="prizeType" className='text-[#8B8D97] text-xs xs:text-sm'>
              Prize Type
            </label>
            <h2 className='font-medium capitalize'>
              {contest?.prizeType}
            </h2>
          </div>
          {
            contest?.prizeType === "money" && (
              <div className='col-span-2'>
                <label htmlFor="cashPrize" className='text-[#8B8D97] text-xs xs:text-sm'>
                  Cash Prize
                </label>
                <h2 className='font-medium'>
                  {formatToIndianCurrency(contest?.prizeInfo?.amount)}
                </h2>
              </div>
            )
          }
          {
            contest?.prizeType === "mobile" && (
              <div className='col-span-2'>
                <label htmlFor="mobileName" className='text-[#8B8D97] text-xs xs:text-sm'>
                  Mobile Name
                </label>
                <h2 className='font-medium'>
                  {contest?.prizeInfo?.mobileName}
                </h2>
              </div>
            )
          }
          {
            contest?.prizeType === "car" && (
              <div className='col-span-1'>
                <label htmlFor="carName" className='text-[#8B8D97] text-xs xs:text-sm'>
                  Car Name
                </label>
                <h2 className='font-medium'>
                  {contest?.prizeInfo?.carName}
                </h2>
              </div>
            )
          }
          {
            contest?.prizeType === "car" && (
              <>
                <button onClick={handleOpen} variant="gradient"
                  className='text-sm text-start'>
                  View More
                </button>
                <Dialog open={open} handler={handleOpen}>
                  <div className='flex justify-between items-center'>
                    <DialogHeader>Car Details</DialogHeader>
                    <IconButton onClick={handleOpen}
                      size='md'
                      className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] h-7 w-7 mr-4 border border-[#FF0023] rounded-md"
                    >
                      <Icon
                        icon="material-symbols:close"
                        className="text-lg"
                      />
                    </IconButton>
                  </div>
                  <DialogBody>
                    <div className='grid grid-cols-2 gap-2 text-black text-base sm:text-lg'>
                      <div className='flex flex-col'>
                        <label htmlFor="carName" className="text-sm sm:text-base text-[#858585]">
                          Car Name
                        </label>
                        <p>
                          {contest?.prizeInfo?.carName}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="carModel" className="text-sm sm:text-base text-[#858585]">
                          Car Mode
                        </label>
                        <p>
                          {contest?.prizeInfo?.carModel}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="exShowroomPrice" className="text-sm sm:text-base text-[#858585]">
                          Ex-Showroom Price
                        </label>
                        <p>
                          {formatToIndianCurrency(contest?.prizeInfo?.exShowroomPrice)}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="RTO" className="text-sm sm:text-base text-[#858585]">
                          RTO
                        </label>
                        <p>
                          {formatToIndianCurrency(contest?.prizeInfo?.RTO)}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="insurance" className="text-sm sm:text-base text-[#858585]">
                          Insurance
                        </label>
                        <p>
                          {formatToIndianCurrency(contest?.prizeInfo?.insurance)}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="others" className="text-sm sm:text-base text-[#858585]">
                          Others
                        </label>
                        <p>
                          {formatToIndianCurrency(contest?.prizeInfo?.others)}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="onRoadPrice" className="text-sm sm:text-base text-[#858585]">
                          On-Road Price
                        </label>
                        <p>
                          {formatToIndianCurrency(contest?.prizeInfo?.onRoadPrice)}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <label htmlFor="cityName" className="text-sm sm:text-base text-[#858585]">
                          City Name
                        </label>
                        <p>
                          {contest?.prizeInfo?.cityName}
                        </p>
                      </div>
                    </div>
                  </DialogBody>
                </Dialog></>
            )
          }
          <div className='col-span-1'>
            <label htmlFor="winner" className='text-[#8B8D97] text-xs xs:text-sm'>
              Winner
            </label>
            <h2 className='font-medium'>
              NA
            </h2>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <label htmlFor="remark" className="text-base sm:text-lg text-[#858585]">
          Remark:
        </label>
        <p className=''>
          {contest?.remark}
        </p>
      </div>
    </div>
  )
}
import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { revenueCalculate } from '../../../utils/lottaryGen';
import { formatToIndianCurrency, formatToIndianNumber } from '../../../helpers/numberConversion';

export default function Lotteries({ data }) {

  let contestCompleted = useMemo(() => (
    data?.contests?.filter((lottery) => lottery?.status.toLowerCase() === "completed")
  ), [data])
  let lotteryPending = data?.totalDocuments - contestCompleted?.length;

  let revenue = useMemo(() => (
    data?.Lottary?.reduce((total, data) => {
      return total + (data.status === "COMPLETED" ? revenueCalculate(data?.Totaltickets, data?.ticketPrice, data?.winneramount) : 0)
    }, 0)
  ), [data]);

  return (
    <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mb-[40px]">
      {/* badge -1: Total Earned  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585] mb-2'>Total Revenue</h3>
          </header>
          <div className="flex justify-between items-center">
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {formatToIndianCurrency(0)}
            </h3>
          </div>
        </div>
      </div>
      {/* badge -2: Total Contests  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Total Contests</h3>
            <Icon className='text-[#25BF17] text-[1.9rem]' icon="game-icons:ticket" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {data?.totalDocuments || "NA"}
          </h3>
        </div>
      </div>
      {/* badge -3: Completed Contests  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Completed</h3>
            <Icon icon="icon-park-solid:check-one" className="text-primary text-[1.9rem]" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {contestCompleted?.length !== "undefined" ? contestCompleted?.length : "NA"}
          </h3>
        </div>
      </div>
      {/* badge -4: Pending Contests  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Pending</h3>
            <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {lotteryPending}
          </h3>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';

export default function ProgressBar({ progress, bgColor }) {

  const [animatedProgress, setAnimatedProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedProgress(progress);
    }, 100); // Add slight delay for smooth animation

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [progress]);

  const parentDiv = {
    height: '10px',
    width: '400px',
    backgroundColor: 'white',
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.40) inset",
    borderRadius: '50px',
  }
  const childDiv = {
    height: '100%',
    width: `${animatedProgress}%`,
    backgroundColor: "#25BF17",
    borderRadius: '50px',
    transition: 'width 1s ease-in-out'
  }

  return (
    <div className='h-[9px] xs:h-[10px] w-full sm:w-[300px] xl:w-[400px] bg-white rounded-[50px]'
      style={{
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.40) inset"
      }}>
      <div className='h-full bg-primary rounded-[50px] transition-all duration-1000 ease-in-out'
        style={{
          width: `${animatedProgress}%`
        }}>
      </div>
    </div>
  )
}
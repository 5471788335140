import React from 'react'
import PublicContests from '../adminModule/archiveContests/PublicContests'

export default function AdminArchiveContests() {
  return (
    <div className='p-4'>
      <PublicContests />
    </div>
  )
}

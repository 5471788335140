import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList
} from "@material-tailwind/react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { revenueCalculate } from '../../../utils/lottaryGen.js'
import { formatToIndianNumber } from "../../../helpers/numberConversion.js";
import { customStyles } from "../../../helpers/customStyles.js";
import Popup from "reactjs-popup";

export default function PublicContests() {
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchKey);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  const flatpickrRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getAccessToken, userRole } = useAuth();

  const columns = [
    {
      name: "SL",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      center: true,
      width: "70px"
    },
    {
      name: "Contest ID",
      id: "contestId",
      selector: (row) => (
        <Link to={`view/${row._id}`} state={row} className="text-blue-500 hover:underline">
          {row.contestId}
        </Link>
      ),
      center: true,
      width: "150px"
    },
    {
      name: "Contest Name",
      id: "contestName",
      selector: (row) => row.name,
      center: true,
      width: "220px"
    },
    {
      name: "Prize Type",
      id: "prizeType",
      selector: (row) => row.prizeType,
      center: true,
      width: "120px"
    },
    {
      name: "Prize Information",
      id: "prizeInformation",
      selector: (row) => row.prizeType === "money" ? row.prizeInfo?.amount : row.prizeType === "mobile" ? row.prizeInfo?.mobileName : row.prizeInfo?.carName,
      center: true,
      width: "220px"
    },
    {
      name: "Created At",
      id: "createdAt",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      width: "175px"
    },
    {
      name: "Expected Date",
      id: "expectedDate",
      selector: (row) => moment(row.probablyAnnounceDate).format("DD MMMM YYYY"),
      center: true,
      width: "175px"
    },
    {
      name: "Image",
      id: "image",
      selector: (row) => (
        <div className="rounded-lg">
          <img src={row.horizontalThumbnail} alt="thumbnail" className="h-[55px] w-[100px] bg-center" />
        </div>
      ),
      center: true,
      width: "150px"
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`capitalize flex justify-center items-center gap-2 rounded-md font-medium w-[7.5rem] py-2 ${row.status === "PENDING" ? "text-[#FFCE06] bg-[#FDF9EF]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
          <span className={`${row.status === "PENDING" ? "bg-[#FFCE06]" : "bg-[#25BF17]"} h-2 w-2 rounded-full`}></span>
          <h2>{row.status?.toLowerCase()}</h2>
        </div>
      ),
      center: true,
      width: "150px"
    },
    {
      name: "Revenue",
      selector: (row) => (
        //setRevenue((prev) => prev + revenueCalculate(row.Totaltickets,row.ticketPrice,row.winneramount.toString()))   
        row.status === "COMPLETED" ? revenueCalculate(row.Totaltickets, row.ticketPrice, row.winneramount.toString()) : 0
      ),
      center: true,
      width: "100px"
    },
    {
      name: "Remark",
      id: "remark",
      selector: (row) => row.remark,
      center: true,
      width: "220px"
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-3">
          <Link to={`view/${row._id}`} state={row}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-md border border-[#D9D9D9]">
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
          {loadingStates[row._id] ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            <IconButton onClick={() => restoreContestHandler(row._id)}
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-md border border-[#D9D9D9]">
              <Icon icon="material-symbols:restore-page" className="text-[1.5rem]" />
            </IconButton>
          )}
        </div>
      ),
      center: true,
      width: "120px"
    },
  ];

  const fetchContests = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.getArchivedContests}?page=${currentPage}&limit=${rowsPerPage}&search=${debouncedSearch}&status=${status}&startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Archived Contests");
      }
      const result = await response.json();
      console.log("Archived Contests", result?.response)
      return result?.response;
    } catch (error) {
      throw new Error("Failed to Fetch Archived Contests");
    }
  };
  const { data: archivedContests, isLoading, refetch, isError, error } = useQuery(
    ['archivedContests', currentPage, rowsPerPage, debouncedSearch, status, startDate, endDate],
    fetchContests
  );

  const restoreContestHandler = async (mongoId) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [mongoId]: true,
    }));
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.restoreArchivedContest}/${mongoId}`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })
      const result = await response.json();
      if (!response.ok) {
        toast.error("Failed to restore contest");
        throw new Error("Failed to restore contest");
      }
      console.log(result);
      toast.success("Contest restored Successfully");
      refetch();
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [mongoId]: false,
      }));
    }
  }

  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchKey);
    }, 1000);

    // Clean up timer if the search term changes
    return () => clearTimeout(handler);
  }, [searchKey]);

  let contestsCompleted = useMemo(() => (
    archivedContests?.contests?.filter((lottery) => lottery?.status.toLowerCase() === "completed")
  ), [archivedContests?.contests]);
  let contestsPending = archivedContests?.totalDocuments - contestsCompleted?.length;

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };
  //   let mapData = []?.map((item, index) => {
  //     return { ...item };
  //   });
  //   if (startDate && endDate) {
  //     console.log("DATE...........DATE...........DATE");
  //     const dStart = new Date(startDate);
  //     dStart.setUTCHours(0, 0, 0, 0);
  //     const dEnd = new Date(endDate);
  //     dEnd.setUTCHours(23, 59, 59, 999);
  //     mapData = mapData?.filter((item) => {
  //       const itemDate = new Date(item.createdAt);
  //       return (
  //         itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
  //       );
  //     });
  //   }
  //   if (status !== "") {
  //     if (status === "ALL") {
  //       setFilteredLotteries(mapData);
  //     } else {
  //       mapData = mapData?.filter((item) => item.status === status);
  //     }
  //   }
  //   setFilteredLotteries(mapData);
  // }, [startDate, endDate, status]);

  const clearDatesHandler = () => {
    setStartDate("");
    setEndDate("");
    flatpickrRef.current.flatpickr.clear();
  }

  let revenue = useMemo(() => (
    []?.reduce((total, data) => {
      return total + (data.status === "COMPLETED" ? revenueCalculate(data?.Totaltickets, data?.ticketPrice, data?.winneramount) : 0)
    }, 0)
  ), []);

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Archived Contest List...!
        </h1>
      </div>
    )
  }

  return (
    <div className="">
      <h1 className="text-lg xs:max-sm:text-xl sm:text-2xl font-semibold whitespace-nowrap mt-2 sm:mt-4">
        Archived Contests
      </h1>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3 sm:gap-6 my-6">
        {/* badge -1: Total Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Archived Contests</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="game-icons:ticket" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {archivedContests?.totalDocuments !== "undefined" ? archivedContests?.totalDocuments : "NA"}
            </h3>
          </div>
        </div>
        {/* badge -2: Completed Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Completed</h3>
              <Icon icon="icon-park-solid:check-one" className="text-primary text-[1.9rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {contestsCompleted?.length !== "undefined" ? contestsCompleted?.length : "NA"}
            </h3>
          </div>
        </div>
        {/* badge -3: Pending Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Pending</h3>
              <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {contestsPending}
            </h3>
          </div>
        </div>

      </div>
      <div className="text-sm sm:text-base flex flex-col max-xs:items-start xs:flex-row gap-2 justify-between items-center flex-wrap pb-4">
        <h1 className="text-lg font-semibold">
          List
        </h1>
        <div className="flex gap-4 items-center flex-wrap">
          <div
            className="flex flex-row gap-2 py-2 px-4 w-[300px] lg:w-[350px] max-sm:w-full"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by contest ID & Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex items-center justify-between gap-2 py-2 px-4 max-sm:w-full"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Created Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Status"
              className="text-sm sm:text-base bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="COMPLETED">COMPLETED</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="">ALL</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-3 xs:gap-4 items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-sm sm:text-base flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-2 xs:px-4 py-2 rounded-md items-center gap-2 sm:gap-4 hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    {" "}
                    <Icon
                      icon="fa-solid:file-pdf"
                      className="text-[#BE0A23] text-[1.3rem]"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-2"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] text-[1.3rem]"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        {/* <DataTable
          columns={columns}
          data={filteredLotteries}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        /> */}
        <DataTable
          // title="Contests"
          columns={columns}
          data={archivedContests?.contests}
          customStyles={customStyles}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={archivedContests?.totalDocuments || 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange} />
      </div>
    </div>
  );
}


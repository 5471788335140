import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { formatToIndianCurrency } from '../../../helpers/numberConversion';


export default function Lotteries({ data }) {

  let contestCompleted = useMemo(() => (
    data?.contests?.filter((lottery) => lottery?.status.toLowerCase() === "completed")
  ), [data])
  let lotteryPending = data?.totalDocuments - contestCompleted?.length;

  // let revenue = useMemo(() => (
  //   data?.Lottary?.reduce((total, data) => {
  //     return total + (data.status === "COMPLETED" ? revenueCalculate(data?.Totaltickets, data?.ticketPrice, data?.winneramount) : 0)
  //   }, 0)
  // ), [data]);

  console.log("LOTTERY COMPLETED", contestCompleted)

  return (
    <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mb-[40px]">
      {/* badge -1: Total Earned  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585] mb-2'>Total Revenue</h3>
          </header>
          <div className="flex justify-between items-center">
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {/* {revenue ? "₹" + formatToIndianNumber(revenue) : "NA"} */}
            {formatToIndianCurrency(0)}
            </h3>
            {/* <div className="flex items-center gap-2">
              <Icon icon="twemoji:flag-india" />
              <span className="text-[11px]">INR</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path d="M9.25 4.5L6.25 7.5L3.25 4.5" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div> */}
          </div>
        </div>
        {/* <div>
          <div className='mb-3'><hr /></div>
          <footer className='flex justify-between items-center gap-2'>
            <div className='flex items-center gap-1'>
              <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
              <small className='text-[#198754] text-[10px]'>3.05%</small>
              <small className='text-[9px] text-[#8898AA]'>Since last month</small>
            </div>
            <button className='text-[10px] text-[#25BF17] font-medium'>View Report</button>
          </footer>
        </div> */}
      </div>
      {/* badge -2: Total Contests  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Total Contests</h3>
            <Icon className='text-[#25BF17] text-[1.9rem]' icon="game-icons:ticket" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {data?.totalDocuments || "NA"}
          </h3>
        </div>
        {/* <div>
          <div className='mb-3'><hr /></div>
          <footer className='flex justify-between items-center gap-1'>
            <div className='flex items-center gap-1'>
              <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
              <small className='text-[#198754] text-[10px]'>3.05%</small>
              <small className='text-[9px] text-[#8898AA]'>Since last month</small>
            </div>
          </footer>
        </div> */}
      </div>
      {/* badge -3: Completed Contests  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Completed</h3>
            <Icon icon="icon-park-solid:check-one" className="text-primary text-[1.9rem]" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {data ? contestCompleted !== "undefined" ? contestCompleted?.length : "NA" : "NA"}
          </h3>
        </div>
      </div>
      {/* badge -4: Pending Lotteries  */}
      <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <div>
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Pending</h3>
            <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {lotteryPending ? lotteryPending : "NA"}
          </h3>
        </div>
      </div>
    </div>
  )
}

export const customStyles = {
  headRow: {
    style: {
      fontWeight: 500,
      padding: "10px 0px",
      color: "#212529",
    },
  },
  head: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      borderTop: "1px solid #E9E9E9",
      background: "#FFF",
      fontSize: "15px",
      "@media screen and (max-width: 768px)": {
        fontSize: "13px",
      },
      "@media screen and (min-width: 768px)": {
        fontSize: "15px",
      },
    },
  },
  rows: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      // borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      color: "#565656",
      padding: "5px 0px",
      // fontSize: "14px",
      "@media screen and (max-width: 768px)": {
        fontSize: "13px",
      },
      "@media screen and (min-width: 768px)": {
        fontSize: "14px",
      },
      fontWeight: 400,
      width: "auto",
    },
  },
  pagination: {
    style: {
      // boxShadow: "10px 5px 5px #ddd",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      overflow: "visible",
    },
  },
  tableWrapper: {
    style: {
      overflow: "visible",
    },
  },
  responsiveWrapper: {
    style: {
      overflowX: "auto",
    },
  },
};
import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Icon } from "@iconify/react";
import NotificationsList from "../adminModule/Notifications/NotificationsList";


export default function AdminPanelNotifications() {
  const [isEditForm, setIsEditForm] = useState(false);
  const [updateNotificationsList, setUpdateNotificationsList] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    const newPath = pathname.split("/");
    if (_.includes(newPath, "edit")) {
      setIsEditForm(true);
    } else {
      setIsEditForm(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] py-[1.5rem] font-[500]">
      <div className="flex items-center justify-between px-[1rem]">
        <h1 className="flex items-center gap-[1rem] text-primary text-[1.2rem] sm:text-[1.7rem] font-semibold">
          <Icon icon="mdi:bell-notification" className="text-[2rem]" />
          {isEditForm ? "Edit Notification" : "Notifications"}
        </h1>
        {isEditForm && (
          <Link to={"/admin/notifications"}>
            <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <Icon
                icon="material-symbols:add"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
              <span className="whitespace-nowrap">Create Offer</span>
            </button>
          </Link>
        )}
      </div>
      <div>
        <Outlet context={[setUpdateNotificationsList]} />
      </div>
      <div className="my-[1rem]">
        <NotificationsList updateNotificationsList={updateNotificationsList} setUpdateNotificationsList={setUpdateNotificationsList} />
      </div>
    </div>
  );
}

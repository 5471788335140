import React, { useRef } from 'react'
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom'


export default function AdminPanelOffersBanners() {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div className='p-4'>
      <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem]'>
        <NavLink to={`/admin/offers-banners`}>
          {({ isActive }) => (
            <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive && (pathname === "/admin/offers-banners" || pathname === `/admin/offers-banners/edit/${id}`) ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Offers
            </h2>
          )}
        </NavLink>
        <NavLink to={`/admin/offers-banners/banners`}>
          {({ isActive }) => (
            <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Banners
            </h2>
          )}
        </NavLink>
      </div>
      <Outlet />
    </div>
  )
}

import React from 'react'
import AnnounceWinner from './AnnounceWinner'
import Winner from './Winner'
import { useOutletContext } from 'react-router-dom'

export default function WinnerContainer() {
  const [contest, refetch] = useOutletContext();
  return (
    <div>
      {
        contest?.announced ?
          <Winner /> :
          <AnnounceWinner refetch={refetch} />
      }
    </div>
  )
}
